window.detectBrowser = function () {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Edge";
  } else if (userAgent.match(/android/i)) {
    browserName = "Android";
  } else if (userAgent.match(/iphone/i)) {
    browserName = "iPhone";
  } else {
    browserName = "Unknown";
  }
  return browserName;
}

window.isMobile = function () {
  let isMobile = false;
  if (/Android|iPhone/i.test(navigator.userAgent)) {
    isMobile = true;
  }
  return isMobile;
}

window.isTablet = function () {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  return isTablet;
}

window.waitForDomInjected = (variableName, callback, checkInterval = 100) => {
  const intervalId = setInterval(() => {
    if (window[variableName] !== undefined) {
      clearInterval(intervalId);
      callback(window[variableName]);
    }
  }, checkInterval);
}

window.waitForDomElement = (selector, callback, checkInterval = 100) => {
  const intervalId = setInterval(() => {
    const element = document.querySelector(selector);
    if (element !== null) {
      clearInterval(intervalId);
      callback(element);
    }
  }, checkInterval);
};
