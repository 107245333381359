import { Application } from "stimulus"
import ModalBottomSheetBaseController from "./modal_bottom_sheet_base_controller"

export default class ModalBottomSheetController extends ModalBottomSheetBaseController {
  
  
  activateBottomSheet() {
    this.sheetTarget.style.display = "inline-block";
    this.displayScrim();
    this.initialSheetContentHeight = this.sheetContentTarget.offsetHeight;
    this.sheetContentTarget.style.maxHeight = this.sheetContentTargetHeight();
    setTimeout(() => { this.sheetTarget.style.setProperty("--translateY", `translateY(${this.translateYBase}px)`); }, 0);
    this.showBottomActionsShadow();
    this.disableScroll();
  }

  sheetContentTargetHeight() {
    return this.sheetContentHeight ? this.sheetContentHeight : this.initialSheetContentHeight
  }

  activateModal() {
    this.showBottomActionsShadow();
    const overModal = this.overModal();
    if (overModal) {
      this.sheetTarget.classList.add("modal-over")
    }
    this.bootstraModalElement = new bootstrap.Modal(this.sheetTarget);
    this.bootstraModalElement.show();
    if (overModal) {
      document.querySelectorAll(".modal-backdrop")[1]?.classList.add("modal-backdrop-over")
    }
  }

  overModal() {
    var result = false;
    document.querySelectorAll(".modal").forEach(modal => {
      if( modal.offsetWidth > 0 || modal.offsetHeight > 0 ) { result = true }
    });
    return result
  }

  hiddenHandlerRest() {
    this.sheetTarget.addEventListener("hidden.bs.modal", () => {
      this.sheetTarget.remove();
    })
  }

  displayScrim() {
    if (!this.disableScrimValue) {
      this.scrim = document.createElement("div");
      this.scrim.classList.add("bottom-sheet-scrim");
      this.scrim.addEventListener("click", () => this.desactivate() );
      this.sheetTarget.before(this.scrim)
      if (this.overBottomSheetValue != "") {this.overBottomSheet()};
      setTimeout(() => { this.scrim.classList.add("active"); }, 0);
    }
  }

  overBottomSheet() {
    var obs = window[`${this.overBottomSheetValue}`].sheetTarget
    var currentScrim = document.querySelector(".bottom-sheet-scrim.active")
    if(currentScrim) {
      var modalBox = obs.closest(".modal-box");
      var offsetTop = obs.getBoundingClientRect().top
      if (modalBox) {
        offsetTop = obs.getBoundingClientRect().top - obs.closest(".modal-box-content").getBoundingClientRect().top
      }
      this.scrim.style.height = "calc(100vh - 94px)"
      this.scrim.style.top = offsetTop * -1;
    }
  }

  desactivateBottomSheet() {
    this.sheetTarget.style.setProperty("--translateY", `translateY(${-this.translateYBase}px)`);
    setTimeout(() => { this.sheetTarget.style.display = "none"; }, 300);
    if (!this.disableScrimValue && this.scrim) {
      this.scrim.classList.remove("active");
      setTimeout(() => { this.scrim.remove(); }, 300);
    }
    if(this.removeSheet) {
      setTimeout(() => { this.sheetTarget.remove(); }, 300);
    }
    this.isExpandedValue = false;
  }

  desactivateModal() {
    this.bootstraModalElement.hide();
  }

  disableScroll() {
    setTimeout(() => {
      if (this.hasBottomActionsTarget) {
        if (window.displayedFilter != "dayhours") {
          this.sheetContentTarget.addEventListener('touchmove', this.preventDefault, { passive: false });
          this.disableBodyScroll(this.bottomActionsTarget);
        }else {
          this.enableDayHoursScroll();
        }
      } else {
        window.addEventListener('touchmove', this.preventDefault, { passive: false });
      }
      if (this.horizontalScrollValue) {this.enableScroll();}
    }, 250);
  }

  enableDayHoursScroll() {
    this.enableScroll();
    this.clearBodyScrollLocks();
  }

  enableScroll() {
    if (this.hasBottomActionsTarget) {
      this.sheetContentTarget.removeEventListener('touchmove', this.preventDefault, { passive: false });
      this.disableBodyScroll(this.bottomActionsTarget);
    } else {
      window.removeEventListener('touchmove', this.preventDefault, { passive: false });
    }
    this.disableBodyScroll(this.sheetContentTarget);
  }

  touchScrollMoveEvent(event) {
    if (!this.isExpandible()) {
      let clientY = event.touches[0].clientY;
      let move = this.translateYBase - (this.initalScrollY - clientY);
      if (event.cancelable) event.preventDefault();
      if (this.initalScrollY > clientY) {
        let bounceMove = (this.initalScrollY - clientY)/5
        if (bounceMove > this.bounceLimit) { bounceMove = this.bounceLimit}
        move = this.translateYBase - bounceMove;
      }
      this.sheetTarget.style.setProperty("--translateY", `translateY(${move}px)`);
    }
  }

  touchScrollStartEvent(event) {
    if (!this.isExpandible()) {
      this.sheetTarget.style.transition = "unset";
    }
    this.initalScrollY = event.changedTouches[0].clientY;
  }

  touchScrollEndEvent(event) {
    const byGrip = event.target.closest("[data-grip='true']") != null;
    const endScrollY = event.changedTouches[0].clientY;
    this.sheetTarget.style.transition = "transform 300ms cubic-bezier(.4, 0, .2, 1)";
    const activateAction = Math.abs(this.initalScrollY - endScrollY) > 100;
    if (!activateAction) { return; }
    if(this.initalScrollY > endScrollY) {
      if (!this.isExpandible()) {
        this.sheetTarget.style.setProperty("--translateY", `translateY(${this.translateYBase}px)`);
      } else {
        this.expandBottomSheet();
      }
    } else if ((this.initalScrollY < endScrollY) && (this.sheetContentTarget.scrollTop == 0 || byGrip)) {
      this.onFullScreen ? this.desactivate() : this.minimizeBottomSheet();
    }
  }

  expandBottomSheet() {
    if (!this.isExpandedValue && this.sheetContentTarget.scrollHeight > this.sheetContentTarget.offsetHeight) {
      this.expandibleView();
    }
  }

  expandibleView() {
    this.sheetContentTarget.style.maxHeight = "90vh";
    this.isExpandedValue = true;
    this.showBottomActionsShadow();
    this.enableScroll();
  }
  
  minimizeBottomSheet() {
    if (this.isExpandedValue || this.disableDesactivateValue) {
      this.sheetContentTarget.style.maxHeight = this.sheetContentTargetHeight();
      this.isExpandedValue = false;
      this.showBottomActionsShadow();
      this.disableScroll();
    }else {
      this.desactivate()
    }
  }

  desactivate(onlyDesactivate = false) {
    (this.hasCloseTarget && !onlyDesactivate) ? this.closeTarget.click() : super.desactivate();
  }

  close() {
    super.desactivate();
  }
  
  fullScreen() {
    setTimeout(() => {
      this.onFullScreen = true;
      this.expandibleView();
    }, 350);
  }
}

const application = Application.start()
application.register("modal-bottom-sheet", ModalBottomSheetController)