window.addCustomEvent = function (event_type, trackeable_item, trackeable_id, value, extra_param_one = null) {
  const track_params = { 
    event_type: event_type,
    trackeable_item: trackeable_item,
    trackeable_id: trackeable_id,
    value: value,
    extra_param_one: extra_param_one,
    skip_user: true
  };

  fetch("/tracking/custom_events", { 
        method: 'post',
        body: JSON.stringify(track_params),
        headers: { 'Content-Type': 'application/json',
                    'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
  });
}