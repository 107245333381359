import { Application, Controller } from "stimulus"

export default class FakeLinkController extends Controller {
  static values = { src: String, remote: Boolean, blank: Boolean }

  visit(e) {
    e.preventDefault();
    if (this.remoteValue) {
      Rails.ajax({ url: this.srcValue, type: "GET", dataType: "script" })
    } else if (this.blankValue) {
      window.open(this.srcValue, "_blank")
    } else {
      window.location = this.srcValue;
    }
  }
}

const application = Application.start()
application.register("fake-link", FakeLinkController)
