import { Application, Controller } from "stimulus"

export default class NotificationController extends Controller {

    closeNotification() {
        this.element.classList.add("d-none");
      }
  }
  
  const application = Application.start()
  application.register("notification", NotificationController)