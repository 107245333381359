import { Application, Controller } from "stimulus"

export default class InputSearchController extends Controller {
  static targets = ["input", "delete"]

  search() {
    if (this.inputTarget.value == "") {
      this.inputTarget.classList.remove("input-search-not-empty");
      this.deleteTarget.classList.add("d-none");
    } else {
      this.inputTarget.classList.add("input-search-not-empty");
      this.deleteTarget.classList.remove("d-none");
    }
  }

  deleteSearch() {
    this.inputTarget.value = "";
    this.deleteTarget.classList.add("d-none");
    this.inputTarget.focus();
    this.inputTarget.click();
  }

}
  
const application = Application.start()
application.register("input-search", InputSearchController)