import { Application, Controller } from "stimulus"
const bodyScrollLock = require('body-scroll-lock');

export default class ModalBottomSheetBaseController extends Controller {

  static targets = ["header", "sheetContent", "bottomActions", "sheet", "scrim", "handle", "close"]

  static values = { id: String,
                    overBottomSheet: String,
                    isModal: {type: Boolean, default: false},
                    isExpanded: {type: Boolean, default: false},
                    horizontalScroll: {type: Boolean, default: false},
                    autoActivate: {type: Boolean, default: false} }
  scrim;
  bounceLimit = 24;
  translateYBase = !this.isExpandible() ? -976 : -1000;
  expandible;
  onFullScreen = false;
  removeSheet = false;
  bootstraModalElement;
  sheetContentMaxHeight = window.innerHeight * 0.95;
  enableBodyScroll = bodyScrollLock.enableBodyScroll;
  disableBodyScroll = bodyScrollLock.disableBodyScroll;
  clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;
  bottomBar = document.querySelector(".bottom-bar");

  connect() {
    window[this.idValue] = this;
    this.handleDragEvents();
    this.isExpandible();
    this.centerElement();
    if (this.isModalValue) {
      this.sheetTarget.addEventListener("hidden.bs.modal", () => {
        this.clearBodyScrollLocks();
      })
    }
    setTimeout(() => { if(this.autoActivateValue) { this.activate(); } }, 250);
  }
  
  activate() {
    this.sheetContentTarget.scrollTo({ top: 0 });
    this.isModalValue ? this.activateModal() : this.activateBottomSheet();
    if(this.bottomBar) { this.bottomBar.classList.add("d-none"); }
  }

  desactivate() {
    this.clearBodyScrollLocks();
    document.activeElement.blur();
    this.isModalValue ? this.desactivateModal() : this.desactivateBottomSheet();
    if(this.bottomBar) { this.bottomBar.classList.remove("d-none"); }
  }

  clearBodyScrollLocks() {
    window.removeEventListener('touchmove', this.preventDefault, { passive: false });
    this.clearAllBodyScrollLocks();
  }

  restActivate() {
    this.removeSheet = true;
    this.hiddenHandlerRest();
    this.activate();
  }

  isExpandible() {
    this.expandible = this.expandible == null ? ((this.sheetContentTarget.scrollHeight > this.sheetContentTarget.offsetHeight) || this.hasBottomActionsTarget) : this.expandible;
    return true;
  }

  handleDragEvents() {
    this.sheetTarget.addEventListener("touchstart", (e) => { this.touchScrollStartEvent(e); }, {passive: true})
    this.sheetTarget.addEventListener("touchmove", (e) => { this.touchScrollMoveEvent(e); }, {passive: true})
    this.sheetTarget.addEventListener("touchend", (e) => { this.touchScrollEndEvent(e); }, {passive: true});
  }

  centerElement() {
    const scrollElement = this.sheetContentTarget.querySelector("[data-scroll='true']");
    if(scrollElement) {
      scrollElement.scrollIntoView();
    }
  }

  showVerticalShadow(){
    if (this.sheetContentTarget.scrollTop > 0) {
      this.headerTarget.classList.add("header-shadow")
    }else {
      this.headerTarget.classList.remove("header-shadow")
    }
    if (this.hasBottomActionsTarget) {
      if ((this.sheetContentTarget.scrollTop + this.sheetContentTarget.offsetHeight) >= this.sheetContentTarget.scrollHeight) {
        this.bottomActionsTarget.classList.remove("bottom-actions-shadow")
      }else {
        this.bottomActionsTarget.classList.add("bottom-actions-shadow")
      }
    }
  }

  showBottomActionsShadow() {
    if (this.hasBottomActionsTarget) {
      setTimeout(() => { 
        if (this.sheetContentTarget.scrollHeight > this.sheetContentTarget.offsetHeight) {
          this.bottomActionsTarget.classList.add("bottom-actions-shadow")
        }else{
          this.bottomActionsTarget.classList.remove("bottom-actions-shadow")
        }
      }, 250);
    }
  }

  preventDefault(e) {
    if (e.cancelable) e.preventDefault();
  }

}

const application = Application.start()
application.register("modal-bottom-sheet-base", ModalBottomSheetBaseController)
