// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("channels")

import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"

Turbo.session.drive = false
window.Rails = Rails;

if(Rails.fire(document, "rails:attachBindings")) {
  Rails.start();
}

// Required by Google Maps. Overriden where necessary
window.initMap = function() {}

window.ready = function(callbackFunction){
  if(document.readyState != 'loading')
    callbackFunction()
  else
    document.addEventListener("DOMContentLoaded", callbackFunction)
}

// Polyfills
import "form-request-submit-polyfill"

gon.moment = require('moment')

// Common partials
import "../partials/fontawesome"
import "../partials/detect_browser"
import "../partials/track_custom_event"
import "../controllers/fake_link_controller"
import '../controllers/input_search_controller'
import '../controllers/modal_bottom_sheet_controller';
import '../controllers/notification_controller';
